<template>
    <div class="section2">
        <div class="section bordered">
            <h2>User Information</h2>
            <div class="content" data-section-name="userinfo">
                <b-row v-if="isEdit" class="mt-4">
                    <b-col lg="4">
                        <label class="thick">Email<span class="arm-required">*</span></label>
                    </b-col>
                    <b-col lg="4">
                        <b-form-input
                          v-model.trim="people.email"
                          :disabled="isEdit"
                          type="email"
                          placeholder="Please enter your email"
                          aria-describedby="input-live-help email-feedback"
                        />
                    </b-col>
                </b-row>
              <b-row
                  class="mt-5"
                  align-v="center"
              >
                <b-col lg="4">
                  <label class="thick">
                    Link ORCID record
                  </label>
                  <p>
                    <a
                        href="https://orcid.org/"
                        target="_blank"
                    >
                      ORCID
                    </a>
                    provides a persistent digital identifier that distinguishes you from other researchers.
                    Please link your ORCID record if you have one.
                  </p>
                </b-col>
                <b-col
                  v-if="orcidIsPresent && orcidValidated"
                  lg="6"
                >
                  <OrcidLink :orcid="orcid" />
                </b-col>

                <b-col
                  v-else-if="orcidIsPresent"
                  lg="6"
                >
                  <b-input
                    :value="orcid"
                    disabled
                  />
                  <b-button
                      class="orcid-button ml-0 mr-0 mt-2 mb-0"
                      variant="outline-secondary"
                      block
                      @click="connectToOrcid"
                  >
                    <img
                        alt="ORCID logo"
                        src="../assets/ORCIDiD_iconvector.svg"
                        width="24"
                        height="24"
                    />
                    Create or connect your ORCID iD
                  </b-button>
                </b-col>

                <b-col
                  v-else
                  lg="6"
                >
                  <b-button
                      class="orcid-button m-0"
                      variant="outline-secondary"
                      block
                      @click="connectToOrcid"
                  >
                    <img
                        alt="ORCID logo"
                        src="../assets/ORCIDiD_iconvector.svg"
                        width="24"
                        height="24"
                    />
                    Create or connect your ORCID iD
                  </b-button>
                </b-col>
              </b-row>
                <b-row class="mt-4">
                    <b-col lg="4">
                        <label class="thick" for="firstName">First name <span class="arm-required">*</span></label>
                        <b-form-input v-model.trim="people.nameFirst" name="people.firstName" placeholder="First name ..." :state="validateState('nameFirst')" aria-describedby="feedback"></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          First Name is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                        <div
                          v-if="validateState('nameFirst') && people.nameFirst.length === 1"
                          class="font-weight-bold"
                        >
                          Note: Please ensure you only intended to have a single character as your first name
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <label class="thick" for="lastName">Last name <span class="arm-required">*</span></label>
                        <b-form-input v-model.trim="people.nameLast" name="people.lastName" placeholder="Last name ..." :state="validateState('nameLast')" aria-describedby="feedback"></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Last Name is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                        <div
                          v-if="validateState('nameLast') && people.nameLast.length === 1"
                          class="font-weight-bold"
                        >
                          Note: Please ensure you only intended to have a single character as your last name
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <label for="middleName">Middle name</label>
                        <b-form-input
                          v-model.trim="people.nameMid"
                          name="people.middleName"
                          placeholder="Middle name ..."
                          :state="validateState('nameMid')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Middle Name must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    
                  <b-col lg="4">
                        <label
                          class="thick"
                          for="street"
                        >
                          Secondary email
                        </label>
                        <b-form-input
                          v-model.trim="people.secondaryEmail"
                          name="people.secondaryEmail"
                          placeholder="Secondary email ..."
                          :state="validateState('secondaryEmail')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Secondary email must conform to a valid email address format
                        </b-form-invalid-feedback>
                    </b-col>

                    <b-col lg="4">
                        <label class="thick" for="phone">Phone <span class="arm-required">*</span></label>
                        <b-form-input
                          v-model.trim="people.phone"
                          name="people.phone"
                          placeholder="Phone ..."
                          :state="validateState('phone')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                          v-if="!$v.people.phone.required"
                        >
                          Phone is required
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                          v-if="!$v.people.phone.maxLength"
                        >
                          Phone can have at most
                          {{$v.people.phone.$params.maxLength.max}}
                          digits
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Phone must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col lg="4">
                        <label class="thick" for="street">Street <span class="arm-required">*</span></label>
                        <b-form-input v-model.trim="people.address1" name="people.street" placeholder="Street ..." :state="validateState('address1')" aria-describedby="feedback"></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Street is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col lg="4">
                        <label for="street2">Street 2</label>
                        <b-form-input
                          v-model.trim="people.address2"
                          name="people.street2"
                          placeholder="Street 2 ..."
                          :state="validateState('address2')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Street 2 must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col lg="4">
                        <label class="thick" for="city">City <span class="arm-required">*</span></label>
                        <b-form-input v-model.trim="people.city" name="people.city" placeholder="City ..." :state="validateState('city')" aria-describedby="feedback"></b-form-input>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          City is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col lg="4">
                        <label class="thick" for="state">State or province <span class="arm-required">*</span></label>
                        <b-form-select v-model.trim="people.state" name="people.state" :options="states" :state="validateState('state')" aria-describedby="feedback"></b-form-select>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          State is required and must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col lg="4">
                        <label for="zipcode" class="thick">Postal code <span class="arm-required">*</span></label>
                        <b-form-input
                          v-model.trim="people.zipcode"
                          name="people.zipcode"
                          placeholder="Postal code ..."
                          :state="validateState('zipcode')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                          v-if="!$v.people.zipcode.required"
                        >
                          Postal code is required
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                          v-if="!$v.people.zipcode.maxLength"
                        >
                          Postal code can have at most
                          {{$v.people.zipcode.$params.maxLength.max}}
                          digits
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Postal code must not include invalid characters such as {{ restrictedCharacters }}
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col lg="4">
                        <label for="country" class="thick">Country <span class="arm-required">*</span></label>
                        <b-form-select
                          v-model.trim="people.countryCode"
                          name="people.country"
                          :options="countries"
                          :state="validateState('countryCode')"
                          aria-describedby="feedback"
                          @change="validateStateCode"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Country is required
                        </b-form-invalid-feedback>
                    </b-col>
                    <b-col lg="4">
                        <label class="thick">Citizenship <span class="arm-required">*</span></label>
                        <b-form-select
                          v-model.trim="people.citizenCountryCode"
                          name="people.citizenCountryCode"
                          :options="countries"
                          :state="validateState('citizenCountryCode')"
                          aria-describedby="feedback"
                        />
                        <b-form-invalid-feedback
                          id="feedback"
                          class="thick"
                        >
                          Citizen status is required
                        </b-form-invalid-feedback>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col lg="4">
                    <label>Preferred pronouns</label>
                    <b-form-select
                        v-model.trim="people.pronounId"
                        name="people.pronounId"
                        :options="pronouns"
                        :state="validateState('pronounId')"
                        aria-describedby="feedback"
                    />
                  </b-col>
                  <b-col
                      v-if="people.pronounId === 4"
                      lg="4"
                  >
                    <label>
                      If you'd like to, please share your pronouns
                    </label>
                    <b-form-input
                        v-model.trim="people.pronounOther"
                        name="people.pronounOther"
                        placeholder="Please enter ..."
                        :state="validateState('pronounOther')"
                        aria-describedby="feedback"
                    />
                    <b-form-invalid-feedback
                        id="feedback"
                        class="thick"
                    >
                      Pronoun description must not include invalid characters such as {{ restrictedSlashCharacters }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              <DemographicsDescriptionSection />

              <!-- gender -->
              <b-row class="mt-3">
                <b-col lg="4">
                  <label
                    class="thick"
                    for="genderCode"
                  >
                    Gender
                    <span class="arm-required">*</span>
                  </label>
                  <b-form-select
                    v-model.trim="people.genderCode"
                    name="people.genderCode"
                    :options="genders"
                    :state="validateState('genderCode')"
                    aria-describedby="feedback"
                  />
                  <b-form-invalid-feedback
                      id="feedback"
                      class="thick"
                  >
                    Gender is required and must not include invalid characters such as {{ restrictedCharacters }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <!-- races -->
              <b-row class="mt-4">
                <b-col lg="12">
                  <label class="thick">
                    Race:
                    <span class="arm-required">*</span>
                  </label>
                  <b-form-checkbox-group
                    v-model.trim="people.races"
                    class="ml-4"
                    :options="races"
                    aria-describedby="feedback"
                    @input="racesUpdated"
                    stacked
                  >
                    <b-form-invalid-feedback
                      v-if="racesChanged"
                      :state="validateState('races')"
                      class="thick"
                    >
                      Race is required
                    </b-form-invalid-feedback>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>

              <!-- ethnicity -->
              <b-row class="mt-4">
                <b-col lg="12">
                  <label class="thick">
                    Ethnicity:
                    <span class="arm-required">*</span>
                  </label>
                  <b-form-radio-group
                    v-model="people.ethnicityCode"
                    class="ml-4"
                    :options="ethnicities"
                    aria-describedby="feedback"
                    name="ethnicities"
                    stacked
                  />
                </b-col>
              </b-row>

              <!-- disability -->
              <b-row class="mt-4">
                <b-col lg="12">
                  <label>
                    <span class="thick">
                      Disabilities:
                    </span>
                    <span class="arm-required">*</span>
                    <div>
                      Do you have any of the following, check all that apply:
                    </div>
                  </label>
                  <b-form-checkbox-group
                    v-model.trim="people.disabilities"
                    class="ml-4"
                    :options="disabilities"
                    aria-describedby="feedback"
                    @input="disabilitiesUpdated"
                    stacked
                  >
                    <b-form-invalid-feedback
                      v-if="disabilitiesChanged"
                      :state="validateState('disabilities')"
                      class="thick"
                    >
                      Disability selection is required
                    </b-form-invalid-feedback>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex"
    import { validationMixin } from "vuelidate";
    import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
    import Config from "@/config"
    import DemographicsDescriptionSection from "@/components/DemographicsDescriptionSection"
    import OrcidLink from "@/components/OrcidLink"

    const oneOrMoreValidator = Config.validations.oneOrMoreValidator
    const zeroOrMoreValidator = Config.validations.zeroOrMoreValidator
    const zeroOrMoreSlashValidator = Config.validations.zeroOrMoreSlashValidator
    const emailValidator = Config.validations.emailValidator
    const stateValidator = Config.validations.stateValidator
    const nameValidator = Config.validations.nameValidator

    export default {
        name: "UserSectionTemplate",
        mixins: [validationMixin],
        components: {
          OrcidLink,
          DemographicsDescriptionSection,
        },
        props: {
            people: {default: null, type: Object},
            isRecordSubmitted: {default: false, type: Boolean},
            isEdit: {default: false, type: Boolean},
            orcidInfo: {required: false, default: ""},
        },
        data() {
            return {
                isEmailUpdated: false,
                racesChanged: false,
                disabilitiesChanged: false,
            }
        },
        computed: {
            ...mapState("app", [
              "countries",
              "states",
              "genders",
              "races",
              "ethnicities",
              "disabilities",
              "pronouns",
            ]),
            ethnicityCode() {
              return this.people?.ethnicityCode
            },
            genderCode() {
              return this.people?.genderCode
            },
            disabilityCode() {
              return this.people?.disabilityCode
            },
            restrictedCharacters() {
              return Config.restrictedCharacters
            },
            orcidIsPresent() {
              return (this.orcidInfo && Object.keys(this.orcidInfo).length > 0) || this.people.orcid
            },
            orcidValidated() {
              return this.people.orcidValidation
            },
            orcid() {
              return this.orcidInfo?.["orcid-identifier"]?.path || this.people.orcid
            },
            restrictedSlashCharacters() {
              return Config.restrictedSlashCharacters
            },
            validationObject() {
              return JSON.stringify(this.$v.people)
            },
        },
        validations: {
            people: {
                email: {
                  required,
                  emailValidator,
                },
                secondaryEmail: {
                  emailValidator,
                },
                nameFirst: {
                    required,
                    nameValidator,
                },
                nameMid: {
                  zeroOrMoreValidator,
                },
                nameLast: {
                    required,
                  oneOrMoreValidator,
                },
                phone: {
                    required,
                    maxLength: maxLength(16),
                    oneOrMoreValidator,
                },
                address1: {
                    required,
                    oneOrMoreValidator,
                },
                address2: {
                  zeroOrMoreValidator,
                },
                city: {
                    required,
                    oneOrMoreValidator,
                },
                state: {
                    stateValidator,
                },
                zipcode: {
                    required,
                    maxLength: maxLength(10),
                    oneOrMoreValidator,
                },
                countryCode: {
                    required,
                    oneOrMoreValidator,
                },
                genderCode: {
                  required,
                  oneOrMoreValidator,
                },
                races: {
                  required,
                },
                ethnicityCode: {
                  required,
                },
                disabilities: {
                  required,
                },
                pronounId: {
                  zeroOrMoreValidator,
                },
                pronounOther: {
                  maxLength: maxLength(50),
                  zeroOrMoreSlashValidator,
                },
                citizenCountryCode: {
                    required,
                    oneOrMoreValidator,
                },
            }
        },
        created() {
            this.$store.dispatch("app/getStates")
            this.$store.dispatch("app/getCountries")
            this.$store.dispatch("app/getGenders")
            this.$store.dispatch("app/getRaces")
            this.$store.dispatch("app/getEthnicities")
            this.$store.dispatch("app/getDisabilities")
            this.$store.dispatch("app/getPronouns")
        },
        watch: {
            "people.races": {
              handler(newVal, oldVal) {
                if (newVal?.includes("-") && !oldVal?.includes("-")) {
                  this.people.races = ["-"]
                } else if (oldVal?.length === 1 && oldVal[0] === "-" && newVal?.length > 1) {
                  this.people.races = newVal.filter(v => v !== "-")
                }
              },
              deep: true,
            },
            "people.disabilities": {
              handler(newVal, oldVal) {
                if (newVal?.includes("D") && !oldVal?.includes("D")) {
                  this.people.disabilities = ["D"]
                } else if (oldVal?.length === 1 && oldVal[0] === "D" && newVal?.length > 1) {
                  this.people.disabilities = newVal.filter(v => v !== "D")
                }
              },
              deep: true,
            },
            "people.pronounId": {
              handler(newVal, oldVal) {
                if (newVal === 4) {
                  this.$v.people.pronounOther.$touch()
                  this.$emit("userSectionValid", !this.$v.people.$invalid)
                }

                if (newVal !== 4 && oldVal === 4) {
                  this.people.pronounOther = null
                }
              },
              deep: true,
            },
            isRecordSubmitted() {
                this.$v.people.$touch();
            },
            validationObject: {
              handler(newVal, oldVal) {
                const a = JSON.parse(newVal)
                const b = JSON.parse(oldVal)
                Object.keys(a.$params).forEach(key => {
                  if (a[key].$model !== b[key].$model) {
                    this.$v.people[key].$touch()
                    this.$emit("userSectionValid", !this.$v.people.$invalid)
                  }
                })
              },
              deep: true,
            },
            "people.secondaryEmail":{
              handler(value){
                // remove secondaryEmail key from people object if the user attempts to fill the field but clears it out
                if (!value && this.people.hasOwnProperty('secondaryEmail')){
                  delete this.people.secondaryEmail
                }
              },
              deep: true,
            }
        },
        methods: {
          racesUpdated() {
            if (!this.racesChanged) {
              this.racesChanged = true
            }
          },
          disabilitiesUpdated() {
            if (!this.disabilitiesChanged) {
              this.disabilitiesChanged = true
            }
          },
          validateStateCode(val) {
            // Default the state code to None if the country is not US
            this.people.state = val && val != "US" ? null : this.people.state
          },
            validateState(name) {
                var { $dirty, $error } = this.$v.people[name];
                if (name === "state"){
                  $error = this.people.state === "0" ? true : false
                }
                return $dirty ? !$error : null;
            },
            validateEmail () {
                this.isEmailUpdated = true
                if (this.people.email)
                    this.$store.dispatch("profile/validateEmail", this.people.email)
            },
            connectToOrcid() {
              this.$emit("persistUserInfo")
              const url = new URL(Config.orcid.authenticateUrl)
              url.searchParams.append("response_type", Config.orcid.responseType)
              url.searchParams.append("redirect_uri", Config.orcid.redirectUri)
              url.searchParams.append("client_id", Config.orcid.clientId)
              url.searchParams.append("scope", Config.orcid.scope)
              url.searchParams.append("nonce", Config.orcid.nonce)
              window.open(url.toString(), "_self")
            }
        }
    }
</script>

<style scoped>
</style>
